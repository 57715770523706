<template>
    <div class="config">
        <div>售后反馈</div>
        <div  class="content-box">
            <div class="content-box-left">
                <div class="content-box-left-top">
                    <el-input v-model="title" placeholder="请输入标题"></el-input>
                    <el-input type="textarea" v-model="content" placeholder="请输入反馈的内容…"></el-input>
                    <div class="flex">
                        <div v-for="(item,index) in imageUrl"  :key="index" v-if="imageUrl.length>0"  class="imageBox">
                            <img  :src="item" class="avatar" />
                            <img class="delete" src="../assets/images/delete.png" @click="deleteImage(index)" />
                        </div>
                        <el-upload
                            multiple
                            list-type="picture-card"
                            :action="`${$axios.defaults.baseURL}/api/common/upload`"
                            :headers={token}
                            :show-file-list="false"
                            :on-success="handleAvatarSuccess"
                            :before-upload="beforeAvatarUpload"
                        >
                            <div class="imageBox">
                                <img src="../assets/images/morenImg.png">
                            </div>
                        </el-upload>
                    </div>
                </div>
                <el-button type="primary" round @click="buttonSubmit">提交</el-button>
            </div>
            <div class="content-box-right">
               <div style="overflow: hidden;height: 80%;overflow-y: scroll;margin-bottom: 0.1rem;">
                   <div v-for="(item,index) in list" :key="index" class="listbox" @click="details(item)" style="cursor: pointer">
                       <div class="listbox-line">
                           <div class="listbox-line-text"><span>提交时间</span>{{item.createtime}}</div>
                       </div>
                       <div class="listbox-line">
                           <div class="listbox-line-text"><span>提交反馈</span>{{item.title}}</div>
                       </div>
                       <div class="flex flex-child-center flex-justify-content-between listbox-line no-margin-bottom">
                           <div class="listbox-line-text" style="color: #CF7AE2" v-if="item.status == 0"><span>处理结果</span>{{item.status_text}}</div>
                           <div class="listbox-line-text"  style="color: #26C196" v-if="item.status == 1"><span>处理结果</span>{{item.status_text}}</div>
                           <div class="listbox-line-text"  style="color: #4B83F0" v-if="item.status == 2"><span>处理结果</span>{{item.status_text}}</div>
                           <div>
                               <img src="../assets/images/you.png">
                           </div>
                       </div>
                   </div>
               </div>
                <el-pagination
                    @current-change="handleCurrentChange"
                    background
                    layout="prev, pager, next"
                    :pager-count="9"
                    :total="total_count">
                </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
import store from "../store";

export default {
    name: "feedback",
    data() {
        return {
            title:'',
            content:'',
            disabled: false,
            imageUrl:[],
            token: '',
            list:[],
            total_count: 0,
            page:1,
        }
    },
    mounted() {
        this.token = store.state.user.token
        this.onList()
    },
    methods: {
        /**
         * 跳转详情
         * */
        details(item) {
            this.$router.push({
                name: 'feedbackDetails',
                query: item
            })
        },
        /**
         * 分页
         * */
        handleCurrentChange(val) {
            console.log('val',val)
            this.page = val
            this.onList()
        },
        /**
         * 上传图片
         * */
        handleAvatarSuccess(res) {
            console.log(res);
            this.imageUrl.push(res.data.fullurl)
        },
        beforeAvatarUpload(file) {
            let index = file.name.lastIndexOf(".");
            let extension = file.name.substr(index + 1);
            let extensionList = [
                "png",
                "PNG",
                "jpg",
                "JPG",
                "jpeg",
                "JPEG",
                "bmp",
                "BMP",
            ];
            // const isJPG = file.type === 'image/jpeg' || file.type === 'image/png';
            const isLt2M = file.size / 1024 / 1024 < 5;
            if (!isLt2M) {
                this.$message.error('上传图片大小不能超过 5MB!');
                return false;
            } else if (extensionList.indexOf(extension) < 0) {
                this.$message.error('当前格式不支持');
                return false;
            }
        },
        /**
         * 删除图片
         * */
        deleteImage(i) {
            this.imageUrl.splice(i, 1)
        },
        /**
         * 清空
         * */
        qingkong() {
            this.title = ''
            this.content = ''
            this.imageUrl = []
        },
        /**
         * 提交按钮
         * */
        buttonSubmit() {
            this.$axios.post('api/kuerp/order/index/addSalesFeedback', {
                title:this.title,
                content:this.content,
                images:this.imageUrl
            }).then(res => {
                if (res.data.code == 200) {
                    this.onList();
                    this.qingkong()
                }
            })
        },
        onList() {
            this.$axios.post('api/kuerp/order/index/SalesFeedbackList', {
                page:this.page,
                limit:10

            }).then(res => {
                this.list = res.data.data.list
                this.total_count = res.data.data.count
            })
        },
    },
}
</script>

<style lang="scss" scoped>
.el-pagination {
    display: flex;
    justify-content: center;
}
.imageBox {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    img {
        width: 0.81rem;
        height: 0.81rem;
        margin-right: 0.05rem;
        border-radius: 0.04rem;
    }
    .delete {
        width: 0.35rem;
        height: 0.35rem;
        position: absolute;
        cursor: pointer;
        padding: 0.1rem;
        top: -0.15rem;
        right: -0.1rem;
    }
}
.config {
    height: 100%;
    padding: 0.24rem 0.34rem;
    >div:nth-child(1) {
        font-size: 0.18rem;
        font-weight: 400;
        color: #333333;
        padding-bottom: 0.1rem;
        border-bottom: 0.01rem solid #E4E4E4;
    }
}
::v-deep {
    .el-pagination.is-background .el-pager li:not(.disabled) {
        border: 0.01rem solid #CCCCCC;
        background-color: #fff;
        color: #333;
        font-weight: 400;
    }
    .el-pagination.is-background .el-pager li:not(.disabled):hover {
        color: #4B83F0;
    }

    .el-pagination.is-background .el-pager li:not(.disabled).active {
        background: #F2ECFC;
        border: 0.01rem solid #4B83F0;
        color: #4B83F0 !important;
    }

     .el-pagination.is-background .btn-prev {
        border: 0.01rem solid #CCCCCC;
        background-color: #fff;
        color: #333;
    }
     .el-pagination.is-background .btn-next {
        border: 0.01rem solid #CCCCCC;
        background-color: #fff;
        color: #333;
    }
    .el-button.is-round {
        width: 1.62rem;
        height: 0.52rem;
        background: #4B83F0;
        border-radius: 0.04rem!important;
        margin-top: 0.4rem!important;
    }
    .el-upload--picture-card {
        background: #FAFAFA;
        width: 0.81rem;
        height: 0.81rem;
        line-height: 0.81rem;
    }
    .el-upload-list--picture-card .el-upload-list__item {
        width: 0.81rem;
        height: 0.81rem;
    }
    .el-input__inner {
        border: none;
        background: #FAFAFA;
        border-bottom: 0.01rem solid #EBEBEB;
        height: 0.6rem;
        line-height: 0.6rem;
        font-size: 0.14rem;
        font-weight: 400;
        color: #999999;
        margin-bottom: 0.15rem;
    }
    .el-textarea__inner {
        border: none;
        font-size: 0.14rem;
        font-weight: 400;
        height: 1.34rem;
        background: #FAFAFA;
        margin-bottom: 0.15rem;
    }
}
.content-box {
    width: 100%;
    height: 90%;
    margin-top: 0.3rem;
    display: flex;
    .content-box-left {
        width: 60%;
        margin-right: 0.35rem;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        .content-box-left-top {
            width: 100%;
            height: 4.45rem;
            max-height: 5.25rem;
            background: #FAFAFA;
            border-radius: 0.04rem;
            border: 0.01rem solid #E7E7E7;
            padding: 0 0.25rem;
            overflow: hidden;
            overflow-y: scroll;
        }
    }
    .content-box-right {
        width: 40%;
        .listbox {
            padding: 0 0 0.2rem 0;
            border-radius: 0.04rem;
            border-bottom: 0.01rem solid #E4E4E4;
            font-size: 0.14rem;
            font-weight: 500;
            margin-bottom: 0.1rem;
            color: #333333;
            .listbox-line {
                margin-bottom: 0.05rem;
                img {
                    width: 0.12rem;
                    height: 0.12rem;
                }
                .listbox-line-text {
                    display: flex;
                    align-items: center;
                }
                span {
                    display: block;
                    width: 0.7rem;
                    text-align: right;
                    font-size: 0.14rem;
                    font-weight: 400;
                    color: #666666;
                    margin-right: 0.1rem;
                }
            }
        }
    }
}
</style>
